<template>
	<b-input
		type="text"
		v-model="internalValue"
		:state="inputValidity"
		:disabled="disabled"
		:formatter="quantiteFormatter"
		@change="$emit('change', $event)"
	>
	</b-input>
</template>

<script>
export default {
	name: 'PriceInput',
	props: {
		value: [String, Number],
		disabled: {
			type: Boolean,
			default: () => (false)
		}
	},
	data: () => ({
		state: null,
		internalValue: null
	}),
	computed: {
		inputValidity() {
			if(!this.internalValue) return
			const iv = this.internalValue.toString().split('.')

			// Avec ce ternaire de mort, on vérifie juste la précision et le décimal
			return iv.length == 1 ?
					(parseInt(iv[0]) < 100000 ? null : false)
				: (parseInt(iv[0]) < 100000 && parseInt(iv[1]) < 99 ? null : false)
		}
	},
	created() {
		this.internalValue = this.value
	},
	methods: {
		updateState() {
			let val = this.internalValue
			if(val === '') {
				this.state = null
				this.$emit('input', '')
				return
			}

			this.state = null
			this.$emit('input', val.toString())
		},
		quantiteFormatter(val) {
			const res = val.replace(',', '.')
			if(isNaN(res)) return '1'
			return res
		}
	},
	watch: {
		value(val) {
			this.internalValue = val
		},
		internalValue(val) {
			this.updateState(val)
		}
	}
}
</script>
